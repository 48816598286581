import { useEffect, useState } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
} from "@pancakeswap/uikit";
import Trans from "components/Trans";
import useAuth from "hooks/useAuth";
import { useRouter } from "next/router";
import { useProfile } from "state/profile/hooks";
import { usePendingTransactions } from "state/transactions/hooks";
import ConnectWalletButton from "components/ConnectWalletButton";
import { useGetBnbBalance } from "hooks/useTokenBalance";
import { useTranslation } from "contexts/Localization";
// import { nftsBaseUrl } from 'views/Nft/market/constants'
import { FetchStatus } from "config/constants/types";
import WalletModal, { WalletView, LOW_BNB_BALANCE } from "./WalletModal";
import ProfileUserMenuItem from "./ProfileUserMenutItem";
import WalletUserMenuItem from "./WalletUserMenuItem";
import { NavProps } from "../../../../packages/uikit/src/widgets/Menu/types";

const UserMenu: React.FC<NavProps> = ({ currentLang, setLang, langs }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { account, error } = useWeb3React();
  const { logout } = useAuth();
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions();
  const { balance, fetchStatus } = useGetBnbBalance();
  const { isInitialized, isLoading, profile } = useProfile();
  const [onPresentWalletModal] = useModal(
    <WalletModal initialView={WalletView.WALLET_INFO} />
  );
  const [onPresentTransactionModal] = useModal(
    <WalletModal initialView={WalletView.TRANSACTIONS} />
  );
  const [onPresentWrongNetworkModal] = useModal(
    <WalletModal initialView={WalletView.WRONG_NETWORK} />
  );
  const hasProfile = isInitialized && !!profile;
  const avatarSrc = profile?.nft?.image?.thumbnail;
  const hasLowBnbBalance =
    fetchStatus === FetchStatus.Fetched && balance.lte(LOW_BNB_BALANCE);
  const [userMenuText, setUserMenuText] = useState<string>("");
  const [userMenuVariable, setUserMenuVariable] =
    useState<UserMenuVariant>("default");
  const isWrongNetwork: boolean =
    error && error instanceof UnsupportedChainIdError;

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t("%num% Pending", { num: pendingNumber }));
      setUserMenuVariable("pending");
    } else {
      setUserMenuText("");
      setUserMenuVariable("default");
    }
  }, [hasPendingTransactions, pendingNumber, t]);

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal();
    } else {
      onPresentWalletModal();
    }
  };

  const UserMenuItems = () => {
    return (
      <>
        <WalletUserMenuItem
          hasLowBnbBalance={hasLowBnbBalance}
          isWrongNetwork={isWrongNetwork}
          onPresentWalletModal={onClickWalletMenu}
        />
        <UserMenuItem
          as="button"
          disabled={isWrongNetwork}
          onClick={onPresentTransactionModal}
        >
          {t("Recent Transactions")}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>

        {langs.map((la, index) => (
          <UserMenuItem
            key={index}
            as="button"
            disabled={isWrongNetwork}
            onClick={() => setLang(la)}
          >
            {la.language}
          </UserMenuItem>
        ))}

        {/* <UserMenuDivider />
        <UserMenuItem
          as="button"
          disabled={isWrongNetwork}
          onClick={() => router.push(`${nftsBaseUrl}/profile/${account.toLowerCase()}`)}
        >
          {t('Your NFTs')}
        </UserMenuItem>
        <ProfileUserMenuItem isLoading={isLoading} hasProfile={hasProfile} disabled={isWrongNetwork} />
        <UserMenuDivider /> */}
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t("Disconnect")}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </>
    );
  };

  if (account) {
    return (
      <UIKitUserMenu
        account={account}
        avatarSrc={avatarSrc}
        text={userMenuText}
        variant={userMenuVariable}
      >
        <UserMenuItems />
      </UIKitUserMenu>
    );
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t("Network")} variant="danger">
        <UserMenuItems />
      </UIKitUserMenu>
    );
  }

  return (
    <ConnectWalletButton className="btn btn-primary btn-sm" scale="sm">
      <Box display={["none", , , "block"]}>
        <Trans>CONNECT WALLET</Trans>
      </Box>
      <Box display={["block", , , "none"]}>
        <Trans>CONNECT</Trans>
      </Box>
    </ConnectWalletButton>
  );
};

export default UserMenu;
