import { ResetCSS } from '@pancakeswap/uikit'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import BigNumber from 'bignumber.js'
// import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import FixedSubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import { useInactiveListener } from 'hooks/useInactiveListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment, useEffect, useState } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import { Blocklist, Updaters } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
import '../../public/font/stylesheet.css'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import AOS from 'aos'
import 'aos/dist/aos.css'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  // useEagerConnect();
  usePollCoreFarmData()
  useUserAgent()
  useInactiveListener()
  useSentryUser()
  return null
}

const noOverlayWorkaroundScript = `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)
  const [referrerAddress, setReferrerAddress] = useState('')
  const { account } = useActiveWeb3React()

  let referedby = null

  useEffect(() => {
    let param = new URLSearchParams(window.location.search)
    if (param) {
      setReferrerAddress(param.get('ref'))

      referedby = param.get('ref')
      //  @ts-ignore
      localStorage.setItem('referral', param.get('ref'))
      if (referedby != null) {
        getaccountDetails()
      }
    }
  }, [])

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    
    });
    
  }, [])

  const getaccountDetails = async () => {
    if (account) {
      let post = {
        gotrefered: account,
        referedby,
        createdAt: new Date().toDateString(),
      }
      // save the post
      let response = await fetch('/api/social_mining', {
        method: 'POST',
        body: JSON.stringify(post),
      })

      // get the data
      // let data = await response.json();
    }
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        <meta
          name="keywords"
          content="xdao, xdao games, Social Experiment, NFT, cryptocurrency, Social Mining"
        />
        <meta
          name="description"
          content="XDAO GAMES - The real life Squid Game"
        />
        <meta name="author" content="" />

        <meta
          property="og:image"
          content="https://xdao.games/img/xdao-dp.png"
        />
        <meta
          property="og:description"
          content="XDAO is taking a different approach to becoming the #1 AD space media agency in the world. We’re adding a little more fun to it by gamifying the whole process and rewarding individual’s with lots of money for helping us get to this goal!"
        />
        <meta property="og:url" content="http://www.xdao.games/" />
        <meta
          property="og:title"
          content="XDao Games - The real life Squid Game [XDAO]"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@xdao_games" />
        <meta name="twitter:creator" content="@xdao_games" />
        <meta
          name="twitter:title"
          content="XDAO Games - The real life Squid Game"
        />
        <meta
          name="twitter:description"
          content="XDAO Games is taking a different approach to becoming the #1 AD space media agency in the world. We’re adding a little more fun to it by gamifying the whole process and rewarding individual’s with lots of money for helping us get to this goal!"
        />
        <meta
          name="twitter:image"
          content="https://xdao.games/img/xdao-dp.png"
        />

        <title>XDAO GAMES - The real life Squid Game</title>
        <link rel="icon" href="img/fav/favicon-96x96.png" />

        <link rel="apple-touch-icon" href="img/fav/android-icon-48x48.png" />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="img/fav/android-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="img/fav/apple-icon-114x114.png"
        />

        <link rel="stylesheet" type="text/css" href="css/bootstrap.min.css" />
        <link rel="stylesheet" type="text/css" href="css/aos.css" />
        <link rel="stylesheet" type="text/css" href="css/normalize.css" />
        {/* <link rel="stylesheet" type="text/css" href="css/global.css" /> */}
        <link rel="stylesheet" type="text/css" href="css_old/style.css" />
        <link rel="stylesheet" type="text/css" href="css/faq.css" />
        <link rel="stylesheet" type="text/css" href="css/clock.css" />
        <link rel="stylesheet" type="text/css" href="css/accordion.css" />


        <link
          rel="stylesheet"
          type="text/css"
          href="css/owl.carousel.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="css/owl.theme.default.min.css"
        />
        <link
          href="https://use.fontawesome.com/releases/v5.0.8/css/all.css"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:400,500,600,700"
          rel="stylesheet"
        />

        <script src="js/vendor/modernizr.js"></script>

        {process.env.NODE_ENV !== 'production' && (
          <script
            dangerouslySetInnerHTML={{ __html: noOverlayWorkaroundScript }}
          />
        )}
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <Updaters />
          {/* <GlobalCheckClaimStatus excludeLocations={[]} /> */}
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary =
  process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps, ...appProps }) => {
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment

  // I a commenting this section until we fully finish the front page website addition in termas of links and translated text.
  const getContent = () => {
    if (
      [
        // `/social-mining`,
        // `/ui`,
        // `/`,
        // `/xdao-game`,
        // `/privacy`,
        // `/experiment`,
        // `/aboutsosx`,
        // `/faq`,
      ].includes(appProps.router.pathname)
    ) {
      return (
        <ProductionErrorBoundary>
          {/* <Head>
            <link rel="stylesheet" type="text/css" href="css/style.css" />
          </Head> */}
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ProductionErrorBoundary>
      )
    } else {
      return (
        <ProductionErrorBoundary>
          {/* <Head>
          </Head> */}
          <Menu>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </Menu>
          <ToastListener />
          <FixedSubgraphHealthIndicator />
        </ProductionErrorBoundary>
      )
    }
  }
  return getContent()
}

export default MyApp
